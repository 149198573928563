import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';


function List(props) {
    const [posts, setPosts] = useState([]);
    const [success, setSuccess] = useState(true);

    const wp_rest_url = 'index.php?rest_route=/wp/v2/';
    const endpoint = props.wp_site_url + wp_rest_url + props.wp_post_type;

    useEffect( () => {
        //console.log(endpoint);
        fetch(endpoint)
        .then( response => response.json() )
        .then( result => { setPosts(result); setSuccess(true) } )
        .catch( error => {
            console.error(error);
            setSuccess(false);
        });
        
    }, [endpoint]); // <-- ここに依存関係のある値を配列に追加する：マウント＆アンマウント時のみ実行する：An empty dependency array makes sure useEffect run only once when the component is mounted.

    //const test = [{"id":1,"title":"もも"},{"id":2,"title":"きょきょ"}];
    console.log(posts);

    if( posts.code !== "rest_no_route" && success ) {
        return (
            <>
                <ul id="wp_api_pos-list">
                {posts.map( item => (
                <li key={item.id}>
                    <a href={"#"+item.id} onClick={ e => { e.preventDefault(); handleClick(item); } } >{item.title.rendered}</a>
                    <div className="wp_api_post-id" id={"wp_api_post-id-"+item.id} />
                    { /* <div dangerouslySetInnerHTML={{__html: item.content.rendered}} /> */ }
                </li>
                ))}
                </ul>
            </>
        );
    } else {
        return (<div className="error">WP Site URL or Post Type is error.</div>);
    }

}


function Single(props) {
        const post = props.value;

        function creatMarkup(post) {
            console.log(post.content);
            return ({__html: post.content.rendered});
        }

        return (
            <>
                <a href={"#"+post.id} onClick={ e => { e.preventDefault(); handleClick(post); } } className="button-close">閉じる</a>
                <div className="wp_api_post-content" id={"wp_api_post-content-"+post.id} dangerouslySetInnerHTML={creatMarkup(post)} />
            </>
        );
}


function handleClick(item) {
        //console.log(item.id);
        let renderTarget = document.getElementById("wp_api_post-id-"+item.id);
        if( document.getElementById("wp_api_post-content-"+item.id) == null ) {
            ReactDOM.render(<Single value={item} />, renderTarget);
        } else {
            ReactDOM.render(null, renderTarget);
        }
}


function Inputurl() {
    const [wp_site_url_value, setWp_site_url_value] = useState(localStorage.getItem('wp_site_url'));
    const [wp_post_type_value, setWp_post_type_value] = useState(localStorage.getItem('wp_post_type'));

    return (
        <>
        <form className="wp_rest_api-url">
            <input name="wp_site_url" type="url" value={wp_site_url_value} onChange={e => setWp_site_url_value(e.target.value) } />
            <input name="wp_post_type" type="text" value={wp_post_type_value} onChange={ e => setWp_post_type_value(e.target.value) } />
            <button type="submit" onClick={ e => { e.preventDefault(); userDataSave(); } }>保存</button>
        </form>
        <div id="main" />
        </>
    );

    function userDataSave() {
        localStorage.setItem('wp_site_url', wp_site_url_value);
        const wp_site_url = localStorage.getItem('wp_site_url');
        //console.log(wp_site_url);
        localStorage.setItem('wp_post_type', wp_post_type_value);
        const wp_post_type = localStorage.getItem('wp_post_type');
        //console.log(wp_post_type);
        ReactDOM.render(
            <List wp_site_url={wp_site_url} wp_post_type={wp_post_type} />,
            document.getElementById('main')
            );
    }
}


ReactDOM.render(
    <Inputurl />,
    document.getElementById('root')
    );
    